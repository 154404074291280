import { IS_MAINNET } from '@shared/utils/constant'

export const whitelistedAddresses = [
  'inj17gkuet8f6pssxd8nycm3qr9d9y699rupv6397z',
  'inj1hkhdaj2a2clmq5jq6mspsggqs32vynpk228q3r',
  'inj1m0ly9d7fnyhfwunhxm99s5sufak6tg77c7r743',
  'inj152l7ckqd5d50rg8zjntendffcld66xgmupyssa',
  'inj1x8fdhpr2tuvjd3uru285aa7lhuq79jtkgj2zz5',
  'inj1ytpfjarztm8p9pcx7r5p87mx6pptupmaxr9u02',
  'inj1vsms7pjtdcgywxsl2t7e8fu97jvwjluarwpt0y',
  'inj1darguk2yjuvlaqme6zyzl04nyet2enz53r0tuh',
  'inj1raw97upjqlajfnep8ump6thtvaf4kcu3geu4df',
  'inj1p2t39q4u96hmzc70kwn5l3smtx9dlujytfrtem',
  'inj1excr2xfu8sta38v3ycdvedxp8fjcgzc9csh5r4',
  'inj1fhqnwmmskaaz6j422wyhxt44k04hrfuqcnzzl5',
  'inj1cmwt22ucqfj3z5qhzctzepd9fvlyplsf9tr7pl',
  'inj1y24q489njhg0s5jqe299jdhrapv5sh53vj7vz2',
  'inj1n8ah224h0d9u7cj3zvwg70d2m737nq9u9fnah7',
  'inj1rfhcl7rf3sk880swhhqryefx6yx30m0dn7vq3j',
  'inj16kflx2t7s62wlqwxus92t4puxx3w7mkp6wxd4r',
  'inj1tm0gcmna3zq47z8md6evqc8qkp9zca8mg4wd26',
  'inj1vgrcdgh5t87dscyq4d49aa2nfdp3zulrjrqe5s',
  'inj1zwzq3jrj7qltg9fhn73jsqhhtx7fwxz5e0swnx',
  'inj1995xnrrtnmtdgjmx0g937vf28dwefhkhy6gy5e',
  'inj1q4fgypsqdxk2p5punfhut0stz7jchnx7tc56av',
  'inj1fal0cxxf3qteuv8w9kvelxk3sv4flfrmjvt976',
  'inj1dhjzd8h3dx5s4muwyq5qgk3uhfevlhf69mp84g',
  'inj1qmcv6lachfep0gqv2hwxl2sp6k4ywwegppyl3t',
  'inj18xg8yh445ernwxdquklwpngffqv3agfyt5uqqs',
  'inj133xeq92ak7p87hntvamgq3047kj8jfqhry92a8',
  'inj196g5reh26pmar32t5g3j3g6lu7gv35lehujqzz',
  'inj1u83u3rl3u4lphzapx6mnhgd5hdq7laqc22vmtg',
  'inj1m2yt0yjpr964mgarj6kt6kkunth8lzdtgtm5xz',
  'inj1lz9a8l8eprvkarcw838xgt8h0wrtmn92qpwyhp',
  'inj130htph6huahen660wnyxrw664tay0v3ex4980x',
  'inj14nmruwptduz8ngn9k52vwjll05qrgxhan88r7h',
  'inj1aj8cjrfut4hsdm2nhwg4nn66tghutwvyt5ch9v',
  'inj1nedmtl9mhzxdlqd3ne270craw9552ap7stgcz0',
  'inj1xpc7l2k9l7mqyctdqjpt37wwfc7gqj7nt3vhy4'
]

export const REFERRAL_CONTRACT_ADDRESS = IS_MAINNET
  ? 'inj1t4l7q6y7uzjjufxgcyr6hdugslphdqzqqt8u9x'
  : 'inj1t474vynqdjqzwzyq9clsenjqffatjqurfx95fs'
