
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as competition_45privacy_45policy_45gwagonitKIPiwH3wMeta } from "/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-gwagon.vue?macro=true";
import { default as competition_45privacy_45policy_45own_45your_45assetsCWMPlE9qPyMeta } from "/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-own-your-assets.vue?macro=true";
import { default as competition_45privacy_45policy_45teslaUYgOoKDV4dMeta } from "/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-tesla.vue?macro=true";
import { default as envB67r7ahqJqMeta } from "/home/runner/work/injective-helix/injective-helix/pages/env.vue?macro=true";
import { default as fee_45discountsNnP0BhLLmhMeta } from "/home/runner/work/injective-helix/injective-helix/pages/fee-discounts.vue?macro=true";
import { default as futuresnpHbDHdvqiMeta } from "/home/runner/work/injective-helix/injective-helix/pages/futures.vue?macro=true";
import { default as _91guild_9390ZtHiQJlDMeta } from "/home/runner/work/injective-helix/injective-helix/pages/guild/[guild].vue?macro=true";
import { default as guilds9pFpoT2EeuMeta } from "/home/runner/work/injective-helix/injective-helix/pages/guilds.vue?macro=true";
import { default as index2VwdV4NPDlMeta } from "/home/runner/work/injective-helix/injective-helix/pages/index.vue?macro=true";
import { default as institutionalcGAOunyS6jMeta } from "/home/runner/work/injective-helix/injective-helix/pages/institutional.vue?macro=true";
import { default as competitiong38OTiqeHmMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard/competition.vue?macro=true";
import { default as indexWhkutWQAAGMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard/index.vue?macro=true";
import { default as past_45competitionscMAYZ3IdBuMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard/past-competitions.vue?macro=true";
import { default as leaderboardIhdheiel0pMeta } from "/home/runner/work/injective-helix/injective-helix/pages/leaderboard.vue?macro=true";
import { default as like_45a_45g_45competition_45terms1r0EXala9IMeta } from "/home/runner/work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue?macro=true";
import { default as campaignLJiJgVjAmFMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue?macro=true";
import { default as dashboardsoSU2pauUgMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue?macro=true";
import { default as indexuF84KHvqfxMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/index.vue?macro=true";
import { default as lp_45rewardslLhrruBlGdMeta } from "/home/runner/work/injective-helix/injective-helix/pages/lp-rewards.vue?macro=true";
import { default as maintenanceh39gOlm6VnMeta } from "/home/runner/work/injective-helix/injective-helix/pages/maintenance.vue?macro=true";
import { default as marketsb1HdpQPHOHMeta } from "/home/runner/work/injective-helix/injective-helix/pages/markets.vue?macro=true";
import { default as own_45your_45assets_45competition_45terms7codKIoWzXMeta } from "/home/runner/work/injective-helix/injective-helix/pages/own-your-assets-competition-terms.vue?macro=true";
import { default as playgroundangNhcvgACMeta } from "/home/runner/work/injective-helix/injective-helix/pages/playground.vue?macro=true";
import { default as pointskE4SHUJTaiMeta } from "/home/runner/work/injective-helix/injective-helix/pages/points.vue?macro=true";
import { default as indexPKbXoGXOH0Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/balances/index.vue?macro=true";
import { default as indexvUDqV0ln3gMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/funding-history/index.vue?macro=true";
import { default as indexGXnzm7sDI1Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue?macro=true";
import { default as indexoy8tr6QzuEMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue?macro=true";
import { default as indexq4LDPBlcZiMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/index.vue?macro=true";
import { default as futures_45gridN94WgXYni7Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue?macro=true";
import { default as advanced_45ordersY2qaHKmXGXMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue?macro=true";
import { default as indexiyGTfAbBhYMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue?macro=true";
import { default as order_45historyZiaUXk8I09Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue?macro=true";
import { default as trade_45historyuMMHhPjFQUMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue?macro=true";
import { default as futures9W40KNjEyEMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue?macro=true";
import { default as spot_45gridc1ZB6pm3igMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue?macro=true";
import { default as indextJkZEngQ9TMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue?macro=true";
import { default as order_45historytFHyhlqgpMMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue?macro=true";
import { default as trade_45history5tVZep4F4qMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue?macro=true";
import { default as spotmIGxOW6cWBMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue?macro=true";
import { default as indexARQsdlKOqDMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/positions/index.vue?macro=true";
import { default as grantereOwTYexfc7Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue?macro=true";
import { default as indexzhbqHn8T4LMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue?macro=true";
import { default as authzOFwuU4U3TrMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue?macro=true";
import { default as autosignGiK89gXGNQMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue?macro=true";
import { default as indexc8bOd7pD3dMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/index.vue?macro=true";
import { default as preferencestMvkkSX2x0Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue?macro=true";
import { default as indexcnvSS8QQl6Meta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue?macro=true";
import { default as portfolioIUJFZ6NLTnMeta } from "/home/runner/work/injective-helix/injective-helix/pages/portfolio.vue?macro=true";
import { default as privacy_45policyLDlYaQn8GFMeta } from "/home/runner/work/injective-helix/injective-helix/pages/privacy-policy.vue?macro=true";
import { default as _91ref_93iZ8WCFa1UKMeta } from "/home/runner/work/injective-helix/injective-helix/pages/ref/[ref].vue?macro=true";
import { default as indexIxJAqI0Ts7Meta } from "/home/runner/work/injective-helix/injective-helix/pages/ref/index.vue?macro=true";
import { default as spotT07bwsa8pYMeta } from "/home/runner/work/injective-helix/injective-helix/pages/spot.vue?macro=true";
import { default as swapjOTFhDPqakMeta } from "/home/runner/work/injective-helix/injective-helix/pages/swap.vue?macro=true";
import { default as termsuQEhPn6W4WMeta } from "/home/runner/work/injective-helix/injective-helix/pages/terms.vue?macro=true";
import { default as tesla_45competition_45termsXzFIVeg69lMeta } from "/home/runner/work/injective-helix/injective-helix/pages/tesla-competition-terms.vue?macro=true";
import { default as trade_45and_45earndFVWQJzi6xMeta } from "/home/runner/work/injective-helix/injective-helix/pages/trade-and-earn.vue?macro=true";
import { default as indexZz1zReA2vsMeta } from "/home/runner/work/injective-helix/injective-helix/pages/trading-bots/index.vue?macro=true";
import { default as indexz0GzSFxN16Meta } from "/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue?macro=true";
import { default as transferILiF6UTtdtMeta } from "/home/runner/work/injective-helix/injective-helix/pages/transfer.vue?macro=true";
import { default as vaultsrSXbVzbtbJMeta } from "/home/runner/work/injective-helix/injective-helix/pages/vaults.vue?macro=true";
export default [
  {
    name: "competition-privacy-policy-gwagon",
    path: "/competition-privacy-policy-gwagon",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-gwagon.vue")
  },
  {
    name: "competition-privacy-policy-own-your-assets",
    path: "/competition-privacy-policy-own-your-assets",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-own-your-assets.vue")
  },
  {
    name: "competition-privacy-policy-tesla",
    path: "/competition-privacy-policy-tesla",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/competition-privacy-policy-tesla.vue")
  },
  {
    name: "env",
    path: "/env",
    meta: envB67r7ahqJqMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/env.vue")
  },
  {
    name: "fee-discounts",
    path: "/fee-discounts",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/fee-discounts.vue")
  },
  {
    name: "futures",
    path: "/futures",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/futures.vue")
  },
  {
    name: "guild-guild",
    path: "/guild/:guild()",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/guild/[guild].vue")
  },
  {
    name: "guilds",
    path: "/guilds",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/guilds.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/index.vue")
  },
  {
    name: "institutional",
    path: "/institutional",
    meta: institutionalcGAOunyS6jMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/institutional.vue")
  },
  {
    name: leaderboardIhdheiel0pMeta?.name,
    path: "/leaderboard",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard.vue"),
    children: [
  {
    name: "leaderboard-competition",
    path: "competition",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard/competition.vue")
  },
  {
    name: "leaderboard",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard/index.vue")
  },
  {
    name: "leaderboard-past-competitions",
    path: "past-competitions",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/leaderboard/past-competitions.vue")
  }
]
  },
  {
    name: "like-a-g-competition-terms",
    path: "/like-a-g-competition-terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/like-a-g-competition-terms.vue")
  },
  {
    name: lp_45rewardslLhrruBlGdMeta?.name,
    path: "/lp-rewards",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards.vue"),
    children: [
  {
    name: "lp-rewards-campaign",
    path: "campaign",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/campaign.vue")
  },
  {
    name: "lp-rewards-dashboard",
    path: "dashboard",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/dashboard.vue")
  },
  {
    name: "lp-rewards",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/lp-rewards/index.vue")
  }
]
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceh39gOlm6VnMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/maintenance.vue")
  },
  {
    name: "markets",
    path: "/markets",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/markets.vue")
  },
  {
    name: "own-your-assets-competition-terms",
    path: "/own-your-assets-competition-terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/own-your-assets-competition-terms.vue")
  },
  {
    name: "playground",
    path: "/playground",
    meta: playgroundangNhcvgACMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/playground.vue")
  },
  {
    name: "points",
    path: "/points",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/points.vue")
  },
  {
    name: portfolioIUJFZ6NLTnMeta?.name,
    path: "/portfolio",
    meta: portfolioIUJFZ6NLTnMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio.vue"),
    children: [
  {
    name: "portfolio-balances",
    path: "balances",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/balances/index.vue")
  },
  {
    name: "portfolio-history-funding-history",
    path: "history/funding-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/funding-history/index.vue")
  },
  {
    name: "portfolio-history-swap",
    path: "history/swap",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/swap/index.vue")
  },
  {
    name: "portfolio-history-wallet",
    path: "history/wallet",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/history/wallet/index.vue")
  },
  {
    name: "portfolio",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/index.vue")
  },
  {
    name: "portfolio-orders-futures-grid",
    path: "orders/futures-grid",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures-grid.vue")
  },
  {
    name: futures9W40KNjEyEMeta?.name,
    path: "orders/futures",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures.vue"),
    children: [
  {
    name: "portfolio-orders-futures-advanced-orders",
    path: "advanced-orders",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/advanced-orders.vue")
  },
  {
    name: "portfolio-orders-futures",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/index.vue")
  },
  {
    name: "portfolio-orders-futures-order-history",
    path: "order-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/order-history.vue")
  },
  {
    name: "portfolio-orders-futures-trade-history",
    path: "trade-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/futures/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-orders-spot-grid",
    path: "orders/spot-grid",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot-grid.vue")
  },
  {
    name: spotmIGxOW6cWBMeta?.name,
    path: "orders/spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot.vue"),
    children: [
  {
    name: "portfolio-orders-spot",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/index.vue")
  },
  {
    name: "portfolio-orders-spot-order-history",
    path: "order-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/order-history.vue")
  },
  {
    name: "portfolio-orders-spot-trade-history",
    path: "trade-history",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/orders/spot/trade-history.vue")
  }
]
  },
  {
    name: "portfolio-positions",
    path: "positions",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/positions/index.vue")
  },
  {
    name: authzOFwuU4U3TrMeta?.name,
    path: "settings/authz",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz.vue"),
    children: [
  {
    name: "portfolio-settings-authz-granter",
    path: "granter",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/granter.vue")
  },
  {
    name: "portfolio-settings-authz",
    path: "",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/authz/index.vue")
  }
]
  },
  {
    name: "portfolio-settings-autosign",
    path: "settings/autosign",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/autosign.vue")
  },
  {
    name: "portfolio-settings",
    path: "settings",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/index.vue")
  },
  {
    name: "portfolio-settings-preferences",
    path: "settings/preferences",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/settings/preferences.vue")
  },
  {
    name: "portfolio-subaccounts",
    path: "subaccounts",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/portfolio/subaccounts/index.vue")
  }
]
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/privacy-policy.vue")
  },
  {
    name: "ref-ref",
    path: "/ref/:ref()",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/ref/[ref].vue")
  },
  {
    name: "ref",
    path: "/ref",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/ref/index.vue")
  },
  {
    name: "spot",
    path: "/spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/spot.vue")
  },
  {
    name: "swap",
    path: "/swap",
    meta: swapjOTFhDPqakMeta || {},
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/swap.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/terms.vue")
  },
  {
    name: "tesla-competition-terms",
    path: "/tesla-competition-terms",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/tesla-competition-terms.vue")
  },
  {
    name: "trade-and-earn",
    path: "/trade-and-earn",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trade-and-earn.vue")
  },
  {
    name: "trading-bots",
    path: "/trading-bots",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trading-bots/index.vue")
  },
  {
    name: "trading-bots-liquidity-bots-spot",
    path: "/trading-bots/liquidity-bots/spot",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/trading-bots/liquidity-bots/spot/index.vue")
  },
  {
    name: "transfer",
    path: "/transfer",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/transfer.vue")
  },
  {
    name: "vaults",
    path: "/vaults",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/vaults.vue")
  },
  {
    name: "futures-slug",
    path: "/futures/:slug()",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/futures.vue")
  },
  {
    name: "spot-slug",
    path: "/spot/:slug()",
    component: () => import("/home/runner/work/injective-helix/injective-helix/pages/spot.vue")
  }
]